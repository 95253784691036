import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { AllTopicsQuery } from "./AllTopicsQuery";
import { I18nextContext } from "gatsby-plugin-react-i18next";

const getTopicList = () => {
	const topicList = [];
	const topics = AllTopicsQuery();
	topics.allMarkdownRemark.edges.forEach((topicEdge) => {
		topicList.push({
			path: topicEdge.node.fields.slug,
			lang: topicEdge.node.fields.language,
			image: topicEdge.node.frontmatter.featuredimage,
			title: topicEdge.node.frontmatter.title,
			id: topicEdge.node.fields.id,
		});
	});
	return topicList;
};

export const AllTopics = () => {
	const topicList = getTopicList();
	const lng = React.useContext(I18nextContext).language;

	return (
		<div className="flex flex-row flex-wrap mb-8">
			{topicList.map(
				(topic) =>
					topic.lang === lng && (
						<>
							<Link
								to={topic.path}
								className="transition p-4 block relative basis-full md:basis-1/2 lg:basis-1/3 brightness-90 hover:brightness-100"
							>
								<GatsbyImage
									image={getImage(topic.image)}
									className="rounded"
								></GatsbyImage>
								<h1 className="text-2xl uppercase w-3/4 text-center font-sans text-white absolute bottom-1/4 bg-black py-4 left-0 right-0">
									{topic.title}
								</h1>
							</Link>
						</>
					)
			)}
		</div>
	);
};

// export AllTopics;

export const TopicFromSlug = (props) => {
	const {slug} = props;
	const topicList = getTopicList();
	const lng = React.useContext(I18nextContext).language;
	// const slug = edge.node.fields.slug.match(/[^\/]+(?=\/$|$)/)[0];

	return (
		<>
		{topicList.map(
				(topic) =>
					topic.lang === lng &&
					topic.path.match(/[^/]+(?=\/$|$)/)[0] === slug && (
						<>
							{topic.title}
						</>
					)
			)}
		</>
	)
}
