import { graphql, useStaticQuery } from "gatsby";

export const AllTopicsQuery = () => {
	const allTopics = useStaticQuery(graphql`
		query AllTopicsQuery {
			allMarkdownRemark(
				filter: { frontmatter: { templateKey: { eq: "topics" } } }
			) {
				edges {
					node {
						frontmatter {
							featuredimage {
								childImageSharp {
									gatsbyImageData(
										width: 600
										height: 400
										quality: 100
										layout: CONSTRAINED
									)
								}
							}
							title
						}
						id
						fields {
							slug
							language
						}
					}
				}
			}
		}
	`);

	return allTopics;
};
