import * as React from "react";
import { useTranslation } from "react-i18next";

export const NewsletterForm = ({ status, message, onValidated }) => {
	let email;
	const {t} = useTranslation();
	const submit = () =>
		email &&
		email.value.indexOf("@") > -1 &&
		onValidated({
			EMAIL: email.value,
		});

	return (
		<>
			{/* <div class="flex justify-center"> */}
				<div class="my-3 w-full">
					<div className="input-group relative flex flex-wrap items-stretch w-full mb-4">
						<input
							className="form-control relative flex-auto min-w-0 inline-block px-3 py-1.5 text-base font-serif text-gray-700 bg-white bg-clip-padding border border-solid border-stone-300 rounded-l transition ease-in-out m-0 focus:text-stone-700 focus:bg-white focus:border-gecko-red focus:outline-none"
							ref={(node) => (email = node)}
							type="email"
							placeholder={t("common:newsletter.newsletterEmail")}
						/>
						<button
							className="btn font-sans inline-block px-6 py-2 bg-gecko-red text-white font-medium text-xs leading-tight uppercase rounded-r hover:bg-opacity-80 focus:outline-none focus:ring-0 transition duration-50 ease-in-out"
							onClick={submit}
							type="button"
						>
							{t("common:newsletter.newsletterSubscribe")}
						</button>
					{/* </div> */}
				</div>
			</div>
            <div className="statusMessage font-sans">
            {status === "sending" && <div className="text-stone-600">sending...</div>}
			{status === "error" && (
				<div
                className="text-gecko-red"
					dangerouslySetInnerHTML={{ __html: message }}
				/>
			)}
			{status === "success" && (
				<div
					className="text-stone-600"
					dangerouslySetInnerHTML={{ __html: message }}
				/>
			)}
            </div>
			
		</>
	);
};
