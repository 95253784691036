import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ReactPlayer from "react-player";
import Seo from "../components/Seo";
import { useTranslation, I18nextContext } from "gatsby-plugin-react-i18next";
import MailchimpSubscribe from "react-mailchimp-subscribe";

import Layout from "../components/Layout";
import { AllTopics, TopicFromSlug } from "../components/AllTopics";
import { NewsletterForm } from "../components/NewsletterForm";

// eslint-disable-next-line
export const IndexPageTemplate = ({
	title,
	description,
	video,
	sectionTitles,
	heroArticle,
	firstHighlightArticle,
	secondHighlightArticle,
	thirdHighlightArticle,
	fourthHighlightArticle,
	heroArticleSlug,
	firstHighlightArticleSlug,
	secondHighlightArticleSlug,
	thirdHighlightArticleSlug,
	fourthHighlightArticleSlug,
}) => {
	const firstHighlightImage = getImage(firstHighlightArticle.featuredimage);
	const secondHighlightImage = getImage(secondHighlightArticle.featuredimage);
	const thirdHighlightImage = getImage(thirdHighlightArticle.featuredimage);
	const fourthHighlightImage = getImage(fourthHighlightArticle.featuredimage);
	const lng = React.useContext(I18nextContext).language;
	const { t } = useTranslation();
	const newsletterUrl =
		"//thegeckoproject.us20.list-manage.com/subscribe/post?u=9ff0007ec60e0f470290ea04d&amp;id=8a2664c5a3";

	return (
		<div>
			<Seo />
			<Link
				to={heroArticleSlug}
				language={lng}
				className="block relative group shadow-xl"
			>
				<div className="h-[88vh] brightness-75 saturate-50 transition group-hover:saturate-100  bg-no-repeat bg-center bg-fixed bg-cover">
					<GatsbyImage
						image={getImage(heroArticle.featuredimage)}
						alt=""
						className="h-full block"
					/>
				</div>

				<div className="absolute bottom-[20%] text-white w-full">
					<div className="gecko-wrapper">
						<div className="flex justify-between">
							<h2 className="text-s md:text-l lg:text-xl font-sans uppercase border-b-2 border-gecko-red">
								<TopicFromSlug slug={heroArticle.main_topic} />
							</h2>
						</div>
						<h1 className="lg:text-6xl text-4xl leading-tight md:text-4xl w-full md:w-2/3 lg:w-3/4 font-sans lg:py-4 transition">
							{heroArticle.title}
						</h1>
					</div>
				</div>
			</Link>

			<div className="section about-gecko-section bg-stone-200 w-full shadow-inner">
				<div className="gecko-wrapper py-8 lg:py-12 mx-auto w-2/3 lg:w-1/3">
					<p className="font-serif text-gecko-red text-center font-semibold text-xl  md:text-2xl">
						{description}
					</p>
				</div>
			</div>

			<div className="section highlight-articles-section gecko-wrapper pt-10">
				<h1 className="uppercase text-center text-2xl py-8 text-stone-400">
					{sectionTitles.articles}
				</h1>
				<div className="flex flex-row flex-wrap  mb-8">
					<Link
						to={firstHighlightArticleSlug}
						language={lng}
						className="group p-4 block lg:basis-1/4 md:basis-1/2 basis-full "
					>
						<GatsbyImage
							image={firstHighlightImage}
							className="transition rounded brightness-90 group-hover:brightness-100"
						/>
						<h3 className="text-xl my-2 text leading-none">
							{firstHighlightArticle.title}
						</h3>
						<p className="text-sm text-stone-600 py-4 border-b-2 border-gecko-red">
							{firstHighlightArticle.description}
						</p>
					</Link>
					<Link
						to={secondHighlightArticleSlug}
						language={lng}
						className="group p-4 block lg:basis-1/4 md:basis-1/2 basis-full  "
					>
						<GatsbyImage
							image={secondHighlightImage}
							className="transition rounded brightness-90 group-hover:brightness-100"
						/>
						<h3 className="text-xl my-2 text leading-none">
							{secondHighlightArticle.title}
						</h3>
						<p className="text-sm text-stone-600 py-4 border-b-2 border-gecko-red">
							{secondHighlightArticle.description}
						</p>
					</Link>
					<Link
						to={thirdHighlightArticleSlug}
						language={lng}
						className="group p-4 block lg:basis-1/4 md:basis-1/2 basis-full "
					>
						<GatsbyImage
							image={thirdHighlightImage}
							className="transition rounded brightness-90 group-hover:brightness-100"
						/>
						<h3 className="text-xl my-2 text leading-none">
							{thirdHighlightArticle.title}
						</h3>
						<p className="text-sm text-stone-600 py-4 border-b-2 border-gecko-red">
							{thirdHighlightArticle.description}
						</p>
					</Link>
					<Link
						to={fourthHighlightArticleSlug}
						language={lng}
						className="group p-4 block lg:basis-1/4 md:basis-1/2 basis-full  "
					>
						<GatsbyImage
							image={fourthHighlightImage}
							className="transition rounded brightness-90 group-hover:brightness-100"
						/>
						<h3 className="text-xl my-2 text leading-none">
							{fourthHighlightArticle.title}
						</h3>
						<p className="text-sm text-stone-600 py-4 border-b-2 border-gecko-red">
							{fourthHighlightArticle.description}
						</p>
					</Link>
				</div>
			</div>
			<div className="section featured-video-section bg-gecko-red w-full py-10 shadow md:shadow-l">
				<div className="gecko-wrapper lg:w-1/2">
					<h1 className="uppercase text-center text-2xl pb-8 text-white">
						{sectionTitles.video}
					</h1>
					<div className="relative pt-[56.25%] opacity-95 hover:opacity-100">
						<ReactPlayer
							url={video.url}
							style={{ position: "absolute", top: 0, left: 0 }}
							width="100%"
							height="100%"
							light={true}
						/>
					</div>
					<h3 className="pt-4 text-l md:text-xl my-2 text-white text-center leading-none">
						{video.title}
					</h3>
					<p className="text-xs md:text-sm text-white py-4 text-center border-t-2 border-white">
						{video.description}
					</p>
				</div>
			</div>

			<div className="section about-gecko-section bg-stone-200 w-full shadow-inner">
				<div className="gecko-wrapper py-8 lg:py-20 mx-auto w-2/3 lg:w-1/3">
					<h1 className="uppercase text-center text-xl text-stone-400">
						{t("common:newsletter.newsletterTitle")}
					</h1>
					<MailchimpSubscribe
						url={newsletterUrl}
						render={({ subscribe, status, message }) => (
							<NewsletterForm
								status={status}
								message={message}
								onValidated={(formData) => subscribe(formData)}
							/>
						)}
					/>
				</div>
			</div>

			<div className="section all-topics-section pt-10">
				<div className="gecko-wrapper">
					<h1 className="uppercase text-center text-2xl py-8 text-stone-400">
						{sectionTitles.topics}
					</h1>
					<AllTopics />
				</div>
			</div>
		</div>
	);
};

const IndexPage = ({ data }) => {
	const frontmatter = data.file.childJazz;

	return (
		<Layout>
			<IndexPageTemplate
				title={frontmatter.title}
				description={frontmatter.description}
				video={frontmatter.video}
				heroArticle={frontmatter.heroArticle.frontmatter}
				sectionTitles={frontmatter.sectionTitles}
				firstHighlightArticle={
					frontmatter.highlightedArticles.firstHighlightArticle.frontmatter
				}
				secondHighlightArticle={
					frontmatter.highlightedArticles.secondHighlightArticle.frontmatter
				}
				thirdHighlightArticle={
					frontmatter.highlightedArticles.thirdHighlightArticle.frontmatter
				}
				fourthHighlightArticle={
					frontmatter.highlightedArticles.fourthHighlightArticle.frontmatter
				}
				heroArticleSlug={frontmatter.heroArticle.fields.slug}
				firstHighlightArticleSlug={
					frontmatter.highlightedArticles.firstHighlightArticle.fields.slug
				}
				secondHighlightArticleSlug={
					frontmatter.highlightedArticles.secondHighlightArticle.fields.slug
				}
				thirdHighlightArticleSlug={
					frontmatter.highlightedArticles.thirdHighlightArticle.fields.slug
				}
				fourthHighlightArticleSlug={
					frontmatter.highlightedArticles.fourthHighlightArticle.fields.slug
				}
			/>
		</Layout>
	);
};

export default IndexPage;

export const pageQuery = graphql`
	query indexPageTemplate($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		file(
			extension: { eq: "json" }
			childLocale: { ns: { eq: "homepage" }, language: { eq: $language } }
		) {
			childJazz {
				title
				description
				heroArticle {
					frontmatter {
						title
						description
						main_topic
						featuredimage {
							childImageSharp {
								gatsbyImageData(
									width: 2000
									quality: 50
									webpOptions: { quality: 70 }
									layout: CONSTRAINED
								)
							}
						}
					}
					fields {
						slug
					}
				}
				highlightedArticles {
					firstHighlightArticle {
						frontmatter {
							title
							description
							main_topic
							featuredimage {
								childImageSharp {
									gatsbyImageData(
										width: 600
										height: 400
										quality: 50
										layout: CONSTRAINED
									)
								}
							}
						}
						fields {
							slug
						}
					}
					secondHighlightArticle {
						frontmatter {
							title
							description
							main_topic
							featuredimage {
								childImageSharp {
									gatsbyImageData(
										width: 600
										height: 400
										quality: 50
										layout: CONSTRAINED
									)
								}
							}
						}
						fields {
							slug
						}
					}
					thirdHighlightArticle {
						frontmatter {
							title
							description
							main_topic
							featuredimage {
								childImageSharp {
									gatsbyImageData(
										width: 600
										height: 400
										quality: 50
										layout: CONSTRAINED
									)
								}
							}
						}
						fields {
							slug
						}
					}
					fourthHighlightArticle {
						frontmatter {
							title
							description
							main_topic
							featuredimage {
								childImageSharp {
									gatsbyImageData(
										width: 600
										height: 400
										quality: 50
										layout: CONSTRAINED
									)
								}
							}
						}
						fields {
							slug
						}
					}
				}
				video {
					description
					title
					url
				}
				sectionTitles {
					articles
					topics
					video
				}
			}
		}
	}
`;
