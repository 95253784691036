import React from "react";
import PropTypes from "prop-types";
// import { Helmet } from "react-helmet";
import { Helmet } from "gatsby-plugin-react-i18next";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

const Seo = ({ title, description, image, article }) => {
	const { pathname } = useLocation();
	const  data  = useStaticQuery(query);
	
	const {
		defaultTitle,
		defaultDescription,
		siteUrl,
		defaultImage,
		twitterUsername,
	} = data.enContent.childJazz;

	const seo = {
		title: title || defaultTitle,
		description: description || defaultDescription,
		image: `${siteUrl}${image || defaultImage}`,
		url: `${siteUrl}${pathname}`,
	};

	return (
		<Helmet title={seo.title} titleTemplate="%s | The Gecko Project">
			<meta name="description" content={seo.description} />
			<meta name="image" content={seo.image} />

			{seo.url && <meta property="og:url" content={seo.url} />}

			{(article ? true : null) && <meta property="og:type" content="article" />}

			{seo.title && <meta property="og:title" content={seo.title} />}

			{seo.description && (
				<meta property="og:description" content={seo.description} />
			)}

			{seo.image && <meta property="og:image" content={seo.image} />}

			<meta name="twitter:card" content="summary_large_image" />

			{twitterUsername && (
				<meta name="twitter:creator" content={twitterUsername} />
			)}

			{seo.title && <meta name="twitter:title" content={seo.title} />}

			{seo.description && (
				<meta name="twitter:description" content={seo.description} />
			)}

			{seo.image && <meta name="twitter:image" content={seo.image} />}
		</Helmet>
	);
};

export default Seo;

Seo.propTypes = {
	deafultTitle: PropTypes.string,
	defaultDescription: PropTypes.string,
	defaultImage: PropTypes.string,
	article: PropTypes.bool,
};

Seo.defaultProps = {
	defaultTitle: null,
	defaultDescription: null,
	defaultImage: null,
	article: false,
};

// const query = graphql`
// 	query SEO {
// 		settingsYaml(templateKey: { eq: "settingsSeo" }) {
// 			defaultTitle: websiteTitle
// 			siteUrl: url
// 			keywords
// 			defaultDescription: description
// 			defaultImage: image
// 			twitterUsername: author
// 		}
// 	}
// `;

const query = graphql`
	query SEO {
		enContent: file(
			childLocale: { language: { eq: "en" }, ns: { eq: "seo" } }
		) {
			childJazz {
				defaultTitle: websiteTitle
				siteUrl: url
				keywords
				defaultDescription: description
				defaultImage: image
				twitterUsername: author
			}
		}
		idContent: file(
			childLocale: { language: { eq: "id" }, ns: { eq: "seo" } }
		) {
			childJazz {
				defaultTitle: websiteTitle
				siteUrl: url
				keywords
				defaultDescription: description
				defaultImage: image
				twitterUsername: author
			}
		}
	}
`;
